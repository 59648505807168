import React from "react";
import { Link } from "gatsby";
import { Col } from "reactstrap";

class NavbarDropdownItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displaySubMenu: false,
        };
    }

    render() {
        return (
            <li>
                <a className="dropdown-item" href={this.props.path}>
                    {this.props.label}
                </a>
            </li>
        );
    }
}

export default NavbarDropdownItem;
