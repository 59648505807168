import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Pagination = ({ pageContext }) => {
    const {
        previousPagePath,
        nextPagePath,
        humanPageNumber,
        numberOfPages,
    } = pageContext;

    return (
        <div className="w-50 mx-auto my-5">
            <nav className="pagination" role="navigation">
                <div>
                    {previousPagePath && (
                        <Link
                            to={previousPagePath}
                            rel="prev"
                            className="btn btn-primary text-white"
                        >
                            Previous
                        </Link>
                    )}
                </div>
                {numberOfPages > 1 && (
                    <div className="pagination-location">
                        Page {humanPageNumber} of {numberOfPages}
                    </div>
                )}
                <div>
                    {nextPagePath && (
                        <Link
                            to={nextPagePath}
                            rel="next"
                            className="btn btn-primary text-white"
                        >
                            Next
                        </Link>
                    )}
                </div>
            </nav>
        </div>
    );
};

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired,
};

export default Pagination;
