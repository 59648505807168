import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Row, Col } from "reactstrap";
import NavbarDropdownItem from "./NavigationDropdownItem";

/**
 * Navigation component
 *
 * The Navigation component takes an array of your Ghost
 * navigation property that is fetched from the settings.
 * It differentiates between absolute (external) and relative link (internal).
 * You can pass it a custom class for your own styles, but it will always fallback
 * to a `site-nav-item` class.
 *
 */
const Navigation = function ({ data, navClass, site }) {
    return (
        <nav className="navbar navbar-expand-md ">
            <Link to="/">
                {site.logo ? (
                    <img
                        className="site-logo"
                        src={site.logo}
                        alt={site.title}
                    />
                ) : (
                    <Img
                        fixed={this.props.data.file.childImageSharp.fixed}
                        alt={site.title}
                    />
                )}
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                    <li className="nav-item active">
                        <a className="nav-link" href="/">
                            Home
                        </a>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {" "}
                            Blog Posts{" "}
                        </a>
                        <ul
                            className="dropdown-menu"
                            id="nav-dropdown"
                            aria-labelledby="navbarDropdownMenuLink"
                        >
                            <li>
                                <a
                                    className="dropdown-item"
                                    href="/blog-posts/featured"
                                >
                                    Featured
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item"
                                    href="/blog-posts/popular"
                                >
                                    Popular
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item"
                                    href="/blog-posts/latest"
                                >
                                    Latest
                                </a>
                            </li>
                            <li className="dropdown-submenu">
                                <a
                                    className="dropdown-item dropdown-toggle"
                                    href="/categories"
                                >
                                    Categories
                                </a>
                                <ul className="dropdown-menu sub-menu">
                                    <NavbarDropdownItem
                                        label={"Local Community and Resources"}
                                        path={
                                            "/tag/local-community-and-resources"
                                        }
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Latest News & Current Events"}
                                        path={"/tag/latest-news-current-events"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"What's New - Fashion & Trends"}
                                        path={"/tag/whats-new-fashion-trends"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Parenting"}
                                        path={"/tag/parenting"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Stay Fit & Healthy"}
                                        path={"/tag/stay-fit-healthy"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Entrepreneurship"}
                                        path={"/tag/entrepreneurship"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Education"}
                                        path={"/tag/education"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Around Home"}
                                        path={"/tag/around-home"}
                                        navclassName={navClass}
                                    />
                                    <NavbarDropdownItem
                                        label={"Finding a Job"}
                                        path={"/tag/finding-a-job"}
                                        navclassName={navClass}
                                    />
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdownMenuLink2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {" "}
                            Job Search
                        </a>
                        <ul
                            className="dropdown-menu"
                            id="nav-dropdown"
                            aria-labelledby="navbarDropdownMenuLink2"
                        >
                            <li>
                                <a
                                    className="dropdown-item"
                                    href="https://www.linkedin.com/jobs/"
                                    target="_blank"
                                >
                                    Linked In Jobs
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="/jobs">
                                    GajGal Jobs
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

Navigation.defaultProps = {
    navClass: `site-nav-item`,
};

Navigation.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    navClass: PropTypes.string,
};

export default Navigation;
