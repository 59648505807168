import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { Tags } from "@tryghost/helpers-gatsby";
import { readingTime as readingTimeHelper } from "@tryghost/helpers";
import { navigate } from "@reach/router";
import { DEFAULT_FEATURE_IMAGE } from "../../constants/backend";
import { deletePost } from "../../constants/mutations";
import { isBrowser } from "../../utils/helpers";

const PostCard = ({ post, hideExtras, isEditable }) => {
    const url = `/${post.id.replace("Ghost__Post__", "")}/`;
    const readingTime = readingTimeHelper(post);

    return (
        <div className={"post-card col col-auto"}>
            <header className="post-card-header pt-3">
                {isEditable && (
                    <div
                        className="post-card-image text-right"
                        style={{
                            backgroundImage: `url(${
                                post.feature_image
                                    ? post.feature_image
                                    : DEFAULT_FEATURE_IMAGE
                            })`,
                        }}
                    >
                        <button
                            className="m-3 btn btn-secondary"
                            onClick={(e) =>
                                navigate("/account/posts/edit/" + post.id)
                            }
                        >
                            <i className="fas fa-edit"></i>
                        </button>

                        <button
                            className="m-3 btn btn-danger"
                            onClick={() => {
                                if (
                                    confirm(
                                        "Are you sure you want to archive this post?"
                                    )
                                )
                                    deletePost(post.id).then(() => {
                                        if (isBrowser) location.reload();
                                    });
                            }}
                        >
                            <i className="fas fa-archive"></i>
                        </button>
                    </div>
                )}
                {!isEditable && (
                    <Link to={url}>
                        <div
                            className="post-card-image text-right"
                            style={{
                                backgroundImage: `url(${
                                    post.feature_image
                                        ? post.feature_image
                                        : DEFAULT_FEATURE_IMAGE
                                })`,
                            }}
                        ></div>
                    </Link>
                )}
                <Link to={url}>
                    <h2 className="post-card-title my-0 ">{post.title}</h2>
                </Link>
                <small>{post.updated_at_pretty}</small>
                {post.tags && (
                    <div className="post-card-tags">
                        <strong>
                            <small>
                                <Tags
                                    post={post}
                                    visibility="public"
                                    autolink={true}
                                />
                            </small>
                        </strong>
                    </div>
                )}
            </header>
            <section
                className="post-card-excerpt noselect clickable"
                onClick={() => navigate(url)}
            >
                {post.excerpt}
            </section>
            {hideExtras !== true && (
                <footer className="post-card-footer pb-3">
                    <div className="post-card-footer-left">
                        <div className="post-card-avatar">
                            {post.primary_author.profile_image ? (
                                <img
                                    className="author-profile-image"
                                    src={post.primary_author.profile_image}
                                    alt={post.primary_author.name}
                                />
                            ) : (
                                <img
                                    className="default-avatar"
                                    src="/images/icons/avatar.svg"
                                    alt={post.primary_author.name}
                                />
                            )}
                        </div>
                        <Link to={"/author/" + post.primary_author.slug}>
                            <span>{post.primary_author.name}</span>
                        </Link>
                    </div>
                    <div
                        className="post-card-footer-right noselect clickable"
                        onClick={() => navigate(url)}
                    >
                        <div>{readingTime}</div>
                    </div>
                </footer>
            )}
        </div>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }),
    }).isRequired,
};

export default PostCard;
