import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import {
    Row,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
} from "reactstrap";

import { Navigation } from ".";
import { auth } from "../../utils/auth";
// Styles
import "../../assets/styles/app.css";
import "../../assets/styles/font-awesome.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "../../assets/styles/theme.css";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/help.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/image.min.js";
import { navigate } from "@reach/router";
import { hasPermissionsOf } from "../../utils/helpers";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownEnabled: false,
            currentUser: null,
        };
    }

    componentDidMount() {
        if (auth.isAuthenticated()) {
            auth.getUser().then((currentUser) =>
                this.setState({ currentUser })
            );
        }
    }

    render() {
        const site = this.props.data.allGhostSettings.edges[0].node;
        const twitterUrl = site.twitter
            ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}`
            : null;
        const facebookUrl = site.facebook
            ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}`
            : null;
        return (
            <>
                <Helmet>
                    <html lang={site.lang} />
                    <style type="text/css">{`${site.codeinjection_styles}`}</style>
                    <body className={this.props.bodyClass} />
                    {/* <script
                        data-ad-client={process.env.GATSBY_GOOGLE_PUBLISHED_ID}
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                    ></script> */}
                </Helmet>

                <div className="viewport">
                    <div className="viewport-top">
                        {/* The main header section on top of the screen */}
                        <header className="site-head">
                            <Row
                                className={
                                    this.props.isHome
                                        ? "site-header"
                                        : "site-header shadow-md"
                                }
                            >
                                <div className="site-mast">
                                    <div className="site-mast-right mx-5">
                                        <nav className="site-nav">
                                            <div className="site-nav-left">
                                                {/* The navigation items as setup in Ghost */}
                                                <Navigation
                                                    data={site.navigation}
                                                    site={site}
                                                    navClass="site-nav-item"
                                                />
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="ml-auto mr-5 my-auto w-25 text-right">
                                    {this.state.currentUser === null && (
                                        <a
                                            className="site-nav-item"
                                            href="#"
                                            onClick={auth.login}
                                        >
                                            Login
                                        </a>
                                    )}

                                    {this.state.currentUser !== null && (
                                        <Dropdown
                                            isOpen={this.state.dropdownEnabled}
                                            toggle={(e) =>
                                                this.setState({
                                                    dropdownEnabled: !this.state
                                                        .dropdownEnabled,
                                                })
                                            }
                                            onMouseEnter={() =>
                                                this.setState({
                                                    dropdownEnabled: true,
                                                })
                                            }
                                        >
                                            <DropdownToggle
                                                className="avatar avatar-sm p-0 ml-auto"
                                                data-toggle="dropdown"
                                                aria-expanded="false"
                                                nav
                                            >
                                                <img
                                                    src={
                                                        this.state.currentUser
                                                            .picture
                                                    }
                                                    alt=""
                                                    className="avatar-img rounded-circle"
                                                />
                                            </DropdownToggle>
                                            <DropdownMenu
                                                right
                                                onMouseLeave={() =>
                                                    this.setState({
                                                        dropdownEnabled: false,
                                                    })
                                                }
                                            >
                                                <DropdownItem href="/account/profile">
                                                    Profile
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem href="/account/posts/liked">
                                                    Liked Posts
                                                </DropdownItem>
                                                {hasPermissionsOf(
                                                    this.state.currentUser,
                                                    "Author"
                                                ) && (
                                                    <React.Fragment>
                                                        <DropdownItem href="/account/posts/view">
                                                            Published Posts
                                                        </DropdownItem>

                                                        <DropdownItem href="/account/posts/new">
                                                            Create New Post
                                                        </DropdownItem>
                                                    </React.Fragment>
                                                )}

                                                <DropdownItem divider />
                                                {hasPermissionsOf(
                                                    this.state.currentUser,
                                                    "Employer"
                                                ) && (
                                                    <React.Fragment>
                                                        <DropdownItem href="/account/jobs/new">
                                                            Publish New Job
                                                        </DropdownItem>
                                                        <DropdownItem href="/account/jobs/view">
                                                            View Published Jobs
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                    </React.Fragment>
                                                )}
                                                <DropdownItem
                                                    onClick={auth.logout}
                                                >
                                                    Logout
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    )}
                                </div>
                            </Row>
                            {this.props.isHome && (
                                <Row>
                                    <div className="site-banner">
                                        <div id="banner-text">
                                            <img
                                                src="/images/Motto.png"
                                                className="img-fluid"
                                            />
                                        </div>
                                        <img
                                            id="cover-image"
                                            src={site.cover_image}
                                            alt="..."
                                        />
                                        {renderQuickSearch()}
                                    </div>
                                </Row>
                            )}
                        </header>

                        {this.props.children}
                    </div>
                    {/* 
                <div className="viewport-bottom">
                     The footer at the very bottom of the screen 
                    <footer className="site-foot">
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left text-dark">
                                 <Link to="/" className="text-dark">
                                    {site.title}
                                </Link>{" "}
                                © 2019 
                            </div>
                            <div className="site-foot-nav-right">
                                TODO: Social media stuff here?
                            </div>
                        </div>
                    </footer>
                </div> */}
                </div>
            </>
        );
    }
}

function renderQuickSearch() {
    return (
        <div id="quick-search">
            <p className="special-heading m-0 text-left">Quick Search</p>
            <form
                className="text-left"
                onSubmit={(e) => {
                    e.preventDefault();
                    let searchTerms = document.getElementById("search-terms")
                        .value;
                    searchTerms = encodeURIComponent(searchTerms);

                    window.open(
                        "https://www.linkedin.com/jobs/search/?keywords=" +
                            searchTerms,
                        "_blank"
                    );
                }}
            >
                <small className="text-dark small-font">
                    <b>Enter Keyword(s):</b>
                </small>
                <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="(i.e. job title, company, city, state, jobcode, etc.)"
                    id="search-terms"
                />
                <div className="w-100">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg my-2 py-2"
                    >
                        Search
                    </button>
                </div>
            </form>
        </div>
    );
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
};

const DefaultLayoutSettingsQuery = (props) => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: { eq: "ghost-icon.png" }) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => <DefaultLayout data={data} {...props} />}
    />
);

export default DefaultLayoutSettingsQuery;
